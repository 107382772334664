<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير الطلبة</h3>
          </v-col>
          <v-col cols="2" align="left">
            <SearchPaymentReport style="display: inline-block" :sections="sections" :employees="paymentEmployees" @clicked="onSearch" />

            <v-btn style="display: inline-block" class="mx-2" @click="printReport()" fab small color="primary" v-if="!isFormApplication">
              <v-icon> mdi-printer </v-icon>
            </v-btn>

            <v-btn style="display: inline-block" class="mx-2" @click="exportExcel()" fab small color="primary" v-if="!isFormApplication">
              <v-icon> mdi-microsoft-excel </v-icon>
            </v-btn>

            <v-btn style="display: inline-block" class="mx-2" @click="printReportForm()" fab small color="primary" v-else>
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid>
      <div v-for="(data, index) in reportData" :key="'t_' + index">
        <v-container class="container" fluid>
          <div>
            <!-- <h3 style="text-align: center">من تاريخ {{ dates[0] }} الى تاريخ {{ dates[1] }}</h3> -->
          </div>
          <table style="width: 100%" class="table-data" v-if="!isFormApplication">
            <tr>
              <th>التسلسل</th>
              <th>رقم الوصل</th>
              <th>الرقم الجامعي</th>
              <th>اسم الطالب</th>
              <th>القسم</th>
              <th>المرحلة</th>
              <th>تاريخ الوصل</th>
              <th>تسديد عن</th>
              <th>المبلغ</th>
              <th>الملاحظات</th>
            </tr>
            <tr v-for="(item, index) in data" :key="item.idStudentFees">
              <td>{{ index + 1 }}</td>
              <td>{{ item.voucherNumber }}</td>
              <td>{{ item.collegeNumber }}</td>
              <td>{{ item.studentName }}</td>
              <td>{{ item.sectionName }}</td>
              <td>{{ item.studentLevel | setLevel }}</td>
              <td>{{ item.paymentDateFormat }}</td>
              <td>{{ item.typeName }}</td>
              <td>
                {{
                  item.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </td>
              <td>{{ item.notes }}</td>
            </tr>
          </table>
          <table v-else style="width: 100%" class="table-data">
            <tr>
              <th>التسلسل</th>
              <th>رقم الاستمارة</th>
              <th>التاريخ</th>
              <th>المبلغ</th>
            </tr>
            <tr v-for="(item, index) in data" :key="item.idFormApplication">
              <td>{{ index + 1 }}</td>
              <td>{{ item.idFormApplication }}</td>
              <td>{{ item.applicationDateFormat }}</td>
              <td>
                {{
                  item.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </td>
            </tr>
          </table>

          <h5 style="padding: 10px; font-size: 12px">
            المجموع :
            {{
              getTotalMoney(data).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h5>
        </v-container>
      </div>
      <h5 style="padding: 20px; font-size: 12px">
        المجموع الكلي :
        {{
          getTotalMoney(allData).toLocaleString("en-US", {
            style: "currency",
            currency: "IQD",
          })
        }}
      </h5>
      <!-- 
      <div v-for="(data, index) in reportData" :key="index">
        <v-card>
          <v-data-table :headers="headers" :items="data" :items-per-page="50" item-key="idStudent" class="table-content" style="margin: 20px 0">
            <template v-slot:[`item.index`]="{ index }">
              <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
            </template>

            <template v-slot:[`item.studentLevel`]="{ item }">
              <p style="padding: 0 3px">{{ item.studentLevel | setLevel }}</p>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <h4 style="padding: 0 3px">
                {{
                  item.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "IQD",
                  })
                }}
              </h4>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="printVoucher(item)">
                <v-icon color="primary"> mdi-printer </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <h5 style="padding: 10px">
            المجموع :
            {{
              getTotalMoney(data).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })
            }}
          </h5>
        </v-card>

        <h5 style="padding: 20px">
          المجموع الكلي :
          {{
            getTotalMoney(allData).toLocaleString("en-US", {
              style: "currency",
              currency: "IQD",
            })
          }}
        </h5>
      </div> -->
    </v-container>
  </div>
</template>

<script>
import SearchPaymentReport from "../components/SearchPaymentReport.vue";
const Excel = require("exceljs");
export default {
  components: {
    SearchPaymentReport,
  },
  data: () => ({
    search: "",
    selected: [],
    reportData: [],
    dataMessage: "لانشاء تقرير يرجى البحث من اعلى اليسار",
    headers: [],
    allData: 0,
    hasData: false,
    showExport: false,
    typeId: 0,
    dataPrint: [],
    voucherIndex: 0,
    isFormApplication: false,
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "رقم الوصل", value: "voucherNumber" },
      { text: "الرقم الجامعي", value: "collegeNumber" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "القسم", value: "sectionName" },
      { text: "للمرحلة", value: "studentLevel" },
      { text: "تاريخ الوصل", value: "paymentDateFormat" },
      { text: "تسديد عن", value: "typeName" },
      { text: "المبلغ", value: "amount" },
      {
        text: " الملاحظات",
        value: "notes",
      },
    ],
    paymentEmployees: [],
    sections: [],

    dailyReport: "",
  }),
  created() {
    this.initialData();
  },
  methods: {
    initialData() {
      let self = this;
      self.overlay = true;
      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrlRegistration}/scienceSections`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
          self.$http.get(`${self.$store.state.apiUrlFees}/paymentEmployee`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }),
        ])
        .then(
          self.$http.spread((sections, paymentEmployee) => {
            sections.data.push({
              idSection: 999,
              sectionName: "جميع الاقسام",
            });
            self.paymentEmployees = paymentEmployee.data;
            self.sections = sections.data;
            self.overlay = false;
          })
        )
        .catch((e) => {
          console.log(e);
          self.overlay = false;
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("student paid");
      worksheet.views = [{ rightToLeft: true }];

      console.log(self.allData);

      worksheet.columns = [
        { header: "رقم الوصل", key: "voucherNumber", width: 50 },
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 50 },
        { header: "القسم", key: "sectionName", width: 30 },
        { header: "المرحلة", key: "level", width: 30 },
        { header: "تاريخ الوصل", key: "date", width: 50 },
        { header: "تسديد عن", key: "typeName", width: 50 },
        { header: "المبلغ", key: "amount", width: 50 },
        { header: "الملاحظات", key: "notes", width: 20 },
      ];

      self.allData.forEach((student) => {
        worksheet.addRow({
          voucherNumber: student.voucherNumber,
          studentName: student.studentName,
          collegeNumber: student.collegeNumber,
          sectionName: student.sectionName,
          level: self.$options.filters.setLevel(student.studentLevel),
          date: student.paymentDateFormat,
          typeName: student.typeName,
          amount: student.amount,
          notes: student.notes,
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `expensesReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    printReport() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");

      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
          @page { margin: 0px !important; }
          body { margin: 1cm !important; }
          .pagebreak {
            clear: both;page-break-after: always;
          }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableData()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.allData).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    printReportForm() {
      let self = this;
      const WinPrint = window.open("", "", "left=0,top=0,width=1000,height=900,toolbar=0,scrollbars=0,status=0");

      WinPrint.document.write(`<!DOCTYPE html>
        <!DOCTYPE html>
          
        <html lang="ar">
        <style>
         
        @media print {
          table { page-break-inside:auto }
          tr    { page-break-inside:avoid; page-break-after:auto }
          @page { margin: 0px !important; }
          body { margin: 1cm !important; }
          .pagebreak {
            clear: both;page-break-after: always;
          }
        }
          body {
            direction : rtl !important;
          }
          table {
            width : 98% !important;
          }
          tr,tr td,th{
            text-align : center !important;
            font-size : 12px !important;
            padding : 5px !important;
          }
        </style>
        <body>
              ${self.printTableDataForm()}
              <h5 style="padding: 20px; font-size: 12px">
              المجموع الكلي :
              ${self.getTotalMoney(self.allData).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
        </body>`);

      self.voucherIndex = 0;
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    getTotalMoney(arrayOfData) {
      if (arrayOfData) {
        let total = arrayOfData.reduce((n, { amount }) => n + amount, 0);
        return total;
      } else {
        return 0;
      }
    },
    printTableData() {
      let self = this;
      let tableData = "";

      for (let i = 0; i < self.dataPrint.length; i++) {
        tableData += `
        <h3 style="text-align:center">${self.datesSelected[0]}-${self.datesSelected[1]} / ${self.reportData[0][0].employeeName}</h3>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
                <th>التسلسل</th>
                <th>رقم الوصل</th>
                <th>الرقم الجامعي</th>
                <th>اسم الطالب</th>
                <th>القسم</th>
                <th>المرحلة</th>
                <th>تاريخ الوصل</th>
                <th>تسديد عن</th>
                <th>المبلغ</th>
              </tr>
              ${self.rowTable(self.dataPrint[i])}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(self.dataPrint[i]).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
            ${i == self.dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
            
          `;
      }
      return tableData;
    },
    rowTable(arrayOfData) {
      let self = this;
      let tdData = "";

      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].voucherNumber}</td>
                <td>${arrayOfData[j].collegeNumber}</td>
                <td>${arrayOfData[j].studentName}</td>
                <td>${arrayOfData[j].sectionName}</td>
                <td>${self.$options.filters.setLevel(arrayOfData[j].studentLevel)}</td>
                <td>${arrayOfData[j].paymentDateFormat}</td>
                <td>${arrayOfData[j].typeName}</td>
                <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })}</td>
              </tr>
            
          `;
      }
      return tdData;
    },

    printTableDataForm() {
      let self = this;
      let tableData = "";

      for (let i = 0; i < self.dataPrint.length; i++) {
        tableData += `
        <h3 style="text-align:center">${self.datesSelected[0]}-${self.datesSelected[1]} / ${self.reportData[0][0].employeeName}</h3>
        <table border='1' style="padding:3px;margin: auto !important;">
            <tr>
              <th>التسلسل</th>
              <th>رقم الاستمارة</th>
              <th>التاريخ</th>
              <th>المبلغ</th>
              </tr>
              ${self.rowTableForm(self.dataPrint[i])}
          </table>
          
          <h5 style="padding: 10px; font-size: 12px">
              المجموع :
              ${self.getTotalMoney(self.dataPrint[i]).toLocaleString("en-US", {
                style: "currency",
                currency: "IQD",
              })}
            </h5>
            ${i == self.dataPrint.length - 1 ? "" : '<div style=" clear: both;page-break-after: always;"></div>'}
            
          `;
      }
      return tableData;
    },
    rowTableForm(arrayOfData) {
      let self = this;
      let tdData = "";

      for (let j = 0; j < arrayOfData.length; j++) {
        self.voucherIndex += 1;
        tdData += `
              <tr>
                <td>${self.voucherIndex}</td>
                <td>${arrayOfData[j].idFormApplication}</td>
                <td>${arrayOfData[j].applicationDateFormat}</td>
                 <td style="font-size:12px">${arrayOfData[j].amount.toLocaleString("en-US", {
                   style: "currency",
                   currency: "IQD",
                 })}</td>
              </tr>
            
          `;
      }
      return tdData;
    },
    onSearch(value) {
      let self = this;
      self.isFormApplication = value.isFormApplication;
      self.datesSelected = value.dates;
      self.allData = [];
      self.reportData = [];
      self.dataPrint = [];
      self.hasData = value.hasData;
      self.allData = value.data.reverse();
      self.reportData = self.sliceArray(value.data.sort((a, b) => b.idStudentFees * 1 - a.idStudentFees * 1));
      self.dataPrint = self.sliceArrayPrint(value.data.sort((a, b) => b.voucherNumber * 1 - a.voucherNumber * 1));
    },
    sliceArrayPrint(arrayOfData) {
      let begin = 0;
      let end = 50;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 50);
      console.log(Math.ceil(arrayOfData.length / 50));

      for (let i = 0; i < numberOfIteration; i++) {
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 50;
      }
      return newArray;
    },
    sliceArray(arrayOfData) {
      let begin = 0;
      let end = 50;
      let newArray = [];
      let numberOfIteration = Math.ceil(arrayOfData.length / 50);
      console.log(Math.ceil(arrayOfData.length / 50));

      for (let i = 0; i < numberOfIteration; i++) {
        console.log(end * (i + 1));
        newArray.push(arrayOfData.slice(begin, end * (i + 1)));
        begin += 50;
      }
      return newArray;
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      } else if (level == 10) {
        return "خريج";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-data tr th,
.table-data tr td,
.table-data {
  text-align: center !important;
  border: 1px solid #ccc !important;
}

.printable-table {
  display: none !important;
}

@media print {
  @page {
    size: A5 landscape !important;
    margin: 0.1cm;
  }
  @page :left {
    margin: 0.1cm !important;
  }

  @page :right {
    margin: 0.1cm !important;
  }
  .not-printable {
    display: none !important;
  }
  th,
  td {
    font-size: 12px;
  }
  .printable-table {
    display: block !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 10px !important;
  }

  #barcode {
    width: 200px !important;
    height: 30px !important;
  }

  .center-print {
    background: #ffffff !important;
  }

  img {
    width: 230px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
</style>
